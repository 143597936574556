import React from "react";
import styles from "./temp.module.css";
import logo from "../..//Assets/TempWebsite/MM Logo Only.png";
import logo2 from "../..//Assets/TempWebsite/MM Only Right Align (White)Untitled-1.png";
import bgimg from "../../Assets/TempWebsite/bg.jpg";
const TempProduction = () => {
  return (
    <div className={styles.mainContent}>
      <div>
        <img src={bgimg} className={styles.bgimg} alt="background-mm" />
      </div>
      <div className={`p-2 mb-3 d-flex align-items-end ${styles.headerCenter}`}>
        <div style={{ flex: "1" }}>
          <img src={logo} alt="main logo" className={styles.mainLogo} />
          <img
            src={logo2}
            alt="main logo title"
            className={styles.mainLogoTitle}
          />
        </div>
        <div className="text-center" style={{ marginRight: "1%" }}>
          <h1 className={styles.midText}>Effective</h1>
          <h2 className={styles.midCommunication}>communication</h2>
        </div>
      </div>
      <div className="text-center" style={{ marginTop: "5rem" }}>
        <div className={styles.bottomLeft}>
          <h1>We're in pre-production</h1>
          <h2
            style={{
              fontWeight: "bolder",
              fontSize: "3rem",
            }}
          >
            Launching Soon
          </h2>
        </div>
      </div>
      <div className="text-center">
        <div className={styles.bottomRight}>
          <h2
            style={{
              fontWeight: "bolder",
              color: "red",
              textTransform: "uppercase",
            }}
          >
            Contact US
          </h2>

          <div className={styles.links}>
            <h3>
              <a href="tel:+919510430390">+919510430390</a> |{" "}
              <a href="mailto: mediamaestro21@gmail.com">
                mediamaestro21@gmail.com
              </a>
            </h3>
            <h3>
              <a href="tel:+917984643340">+917984643340</a> |{" "}
              <a href="mailto: pranshuyshah.work@gmail.com">
                pranshuyshah.work@gmail.com
              </a>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TempProduction;
