import React from "react";
import TempProduction from "./Components/status-build/tempProduction";
import "./index.css";
function App() {
  return (
    <>
      <TempProduction />
    </>
  );
}

export default App;
